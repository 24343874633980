import $ from "jquery";
import Rails from "@rails/ujs";
import { v4 as uuidv4 } from "uuid";
import { i18n } from "./Internationalization";
import { subscribeDownloadChannel } from "./channels/exporter";
import toastr from "../plugins/Toastr";

export const initExporter = (callback, selector = ".exporter-link") => {
  $(selector).on("click", (e) => {
    e.preventDefault();
    const uuid = uuidv4();
    const currentHref = $(e.currentTarget).attr("href");
    const url = currentHref.includes("?")
      ? `${currentHref}&uuid=${uuid}`
      : `${currentHref}?uuid=${uuid}`;

    // Update link classes to prevent multiple highlighting, add closing of dropdown for exporter links, and show spinner for exporter links
    $(selector).removeClass("active selected");
    $(selector).parent().find(".active").removeClass("active selected");
    $(e.currentTarget).addClass("active selected");
    const printDropdown = $('[data-loading-icon="spinner"]');
    if (printDropdown) {
      printDropdown.trigger("click");
      printDropdown.find(".g-icon-chevron-down").addClass("tw-hidden");
      printDropdown.find(".spinner").removeClass("tw-hidden");
    }

    subscribeDownloadChannel(
      uuid,
      () => {
        $.get(url);
      },
      callback,
    );
    return false;
  });

  $(".exporter-form").on("submit", (e) => {
    e.preventDefault();
    const form = $(e.target);
    const uuid = uuidv4();
    form.children(".uuid").val(uuid);
    toastr.warning(i18n.t("cross_report.success"), "", {
      timeOut: 0,
      iconClass: "toast-warning-loading",
    });
    subscribeDownloadChannel(
      uuid,
      () => {
        form.off("submit");
        Rails.fire(form[0], "submit");
      },
      callback,
    );
    return false;
  });
};
window.initExporter = initExporter;

export const setExportDownloadLink = (uuid, data) => {
  const downloadReadyNotice = document.querySelector("#download-ready-notice");
  const downloadPendingNotice = document.querySelector(
    "#download-pending-notice",
  );

  if (downloadReadyNotice && downloadPendingNotice) {
    downloadPendingNotice.classList.add("hide");
    downloadReadyNotice.classList.remove("hide");
  }

  const downloadLink = document.querySelector("#download-link");
  if (downloadLink && data.url) {
    downloadLink.href = data.url;
  }
  const printDropdown = document.querySelector('[data-loading-icon="spinner"]');
  if (printDropdown) {
    printDropdown
      .querySelector(".g-icon-chevron-down")
      .classList.remove("tw-hidden");
    printDropdown.querySelector(".spinner").classList.add("tw-hidden");
  }
};

const init = () => {
  if (document.querySelector(".exporter-link")) {
    initExporter(setExportDownloadLink);
  }
};
document.addEventListener("DOMContentLoaded", init);
